//==============================================================================
// Copyright
//
// Allows the automation of copyright year
//==============================================================================
import * as React from 'react';
import classnames from 'classnames';

import { ICopyrightData } from './copyright.data';
import { ICopyrightProps } from './copyright.props.autogenerated';

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * Copyright component
 * @extends {React.PureComponent<ICopyrightProps<ICopyrightData>>}
 */
//==============================================================================
class Copyright extends React.PureComponent<ICopyrightProps<ICopyrightData>> {

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================

    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element {
        const { className } = this.props.config;

        return (
            <div className={classnames('copyright', className)}>
                {this._copyrightTextFormatted()}
            </div>
        );
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================

    //------------------------------------------------------
    //------------------------------------------------------
    private _copyrightTextFormatted(): JSX.Element {
        const { copyrightText } = this.props.config;
        const copyrightYear = new Date().getFullYear().toString();

        return (
            <React.Fragment>
                <span className='copyright__symbol'>©</span>
                <span className='copyright__year'>{copyrightYear}</span>
                {copyrightText && <span className='copyright__text'>{copyrightText}</span>}
            </React.Fragment>
        );
    }
}

export default Copyright;
